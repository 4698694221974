import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { set, del } from '@vue/composition-api'

import { apiTrain } from '@/api'
import store from '@/store'

import { roundNumberPriceTrain } from '@core/utils/filter'

const defaultState = () => ({
  tabIndex: 0,
  payloadSearch: null,
  resultSearchTrain: null,

  isUpdateDataPax: false, // nếu update name hoặc identify thì trigger để update data customer

  trainSelectedArray: [],
  coachSelectedArray: [],

  ticketSelectedArray: [], // NOTE nested array
})

export default {
  namespaced: true,
  state: {
    ...defaultState(),

    loading: false,
    loadingCategory: false,
    loadingSetting: false,
    loadingAppliesPromotions: false,
    // loadingGetSeatByCoach: false,
    loadingPickTicket: false,

    settings: null,
    category: null,

    // ANCHOR profile fee service selected
    defaultServicesFee: null,
    selectedProfileServiceFee: null,

    // ANCHOR: BOOKING DETAIL
    bookingData: null,
  },

  getters: {
    getIsSearchRT: state => state.payloadSearch?.tripType === 'RT',
    getBookingCode: state => state.resultSearchTrain?.bookingCode || '',
    getMaxNumberOfTicketOW: state => state.settings?.maxNumberOfTicketOW || 20,
    getListTrainStation: state => state.category?.listTrainStation || [],
    getListPassengerTarget: state => state.category?.listPassengerTarget || [],
    getPassengerTypeByPassengerTarget: state => passengerTargetCode => (state.category?.listPassengerTarget || []).find(item => item.code === passengerTargetCode) || undefined,
    getListSeatGroup: state => state.category?.listSeatGroup || [],
    getListSeatType: state => state.category?.listSeatType || [],
    getSearchPayloadArray: state => {
      const {
        departure, arrival, departDate, returnDate, tripType,
      } = state.payloadSearch || {}
      if (tripType === 'RT') {
        return [
          {
            departure,
            arrival,
            departDate,
          },
          {
            departure: arrival,
            arrival: departure,
            departDate: returnDate,
          },
        ]
      }
      return [{ departure, arrival, departDate }]
    },
    getResultTrainArray: (state, getters) => {
      const go = state.resultSearchTrain?.listTrain || []
      const back = state.resultSearchTrain?.listTrainReturn || []
      const result = [go]
      if (getters.getIsSearchRT) result.push(back)
      return result
    },
    getTotalCartTicketPrice: (state, getters) => {
      const listPaxTypeCodes = getters.getListPassengerTarget

      return state.ticketSelectedArray.reduce((totalPrice, tickets) => {
        tickets.forEach(ticket => {
          const passengerType = listPaxTypeCodes.find(paxType => paxType.code === ticket.passengerTargetCode)
          const ticketPrice = ticket.ticketPrice
          const discountedPrice = ticketPrice - (ticketPrice * (passengerType.ratio / 100))
          const priceNotPromo = ticket.totalPrice - roundNumberPriceTrain(discountedPrice)
          const priceByPromo = ticket?.applyPromoData?.calcPromotion?.totalPrice ?? priceNotPromo
          const servicesFee = getters.getServiceFeeByPassengerTargetCode(ticket.passengerTargetCode)
          const finalPrice = priceByPromo + servicesFee
          totalPrice += finalPrice
        })
        return totalPrice
      }, 0)
    },
    getServiceFeeByPassengerTargetCode: state => code => {
      const field = [2, 7].includes(code) ? 'childAmount' : 'adultAmount'

      const getPrice = configList => {
        const serviceConfig = configList?.find(item => item.ticketType === 'INLAND' && item.airline === 'TRAIN')
        return serviceConfig?.[field] || 0
      }

      const price = state.selectedProfileServiceFee
        ? getPrice(state.selectedProfileServiceFee.serviceFeeConfigs)
        : (state.defaultServicesFee?.[field] || 0)

      return price
    },

    // ANCHOR: BOOKING DETAIL
    getBookingData: state => state.bookingData,
  },

  mutations: {
    RESET_STORE(state) {
      Object.assign(state, { ...defaultState() })
    },

    SET_LOADING(state, { name, value }) {
      state[name] = value
    },
    SET_CATEGORY(state, val) {
      state.category = val
    },
    SET_SETTING(state, val) {
      state.settings = val
    },
    SET_DATA_SEARCH(state, val) {
      state.resultSearchTrain = val
    },
    SET_PAYLOAD_SEARCH(state, val) {
      state.payloadSearch = val
    },
    SET_TAB_INDEX(state, val) {
      state.tabIndex = val
    },
    SET_SEARCH_BOOKING_CODE(state, val) {
      state.searchBookingCode = val
    },
    TOGGLE_IS_UPDATE_DATA_PAX(state) {
      state.isUpdateDataPax = !state.isUpdateDataPax
    },

    SET_INITIAL_SELECT_TRAIN(state) {
      const initTrainArr = [state.resultSearchTrain.listTrain[0]]
      if (!isEmpty(state.resultSearchTrain.listTrainReturn)) {
        initTrainArr.push(state.resultSearchTrain.listTrainReturn[0])
      }
      state.trainSelectedArray = initTrainArr
    },

    // SET_INITIAL_SELECT_COACH(state) { // FIXME case ko có hành trình, empty coachOfTrains => chuyển thành ?action
    //   const initCoachArr = [state.resultSearchTrain.listTrain[0].coachOfTrains[0]]
    //   if (!isEmpty(state.resultSearchTrain.listTrainReturn)) {
    //     initCoachArr.push(state.resultSearchTrain.listTrainReturn[0].coachOfTrains[0])
    //   }
    //   state.coachSelectedArray = initCoachArr
    // },

    SET_INITIAL_SELECT_TICKET(state) {
      const initTicketArr = [[]]
      if (state.payloadSearch.tripType === 'RT') {
        initTicketArr.push([])
      }
      state.ticketSelectedArray = initTicketArr
    },

    SET_SELECTED_TRAIN(state, val) {
      /*
      1. save train data
      2. change coach select to coach.isShow
      */
      const trainClone = cloneDeep(state.trainSelectedArray)
      trainClone[state.tabIndex] = val
      state.trainSelectedArray = trainClone
    },

    // SET_SELECTED_TRAIN(state, val) { // NOTE: OLD
    //   /*
    //   1. save train data
    //   2. change coach select to coach.isShow
    //   */
    //   const trainClone = cloneDeep(state.trainSelectedArray)
    //   trainClone[state.tabIndex] = val
    //   state.trainSelectedArray = trainClone

    //   const coachClone = cloneDeep(state.coachSelectedArray)
    //   const data = val.coachOfTrains.find(coach => coach.isShow)
    //   coachClone[state.tabIndex] = data
    //   state.coachSelectedArray = coachClone
    // },

    // SET_SELECTED_COACH(state, val) {
    //   /*
    //   1. save coach data
    //   2. change coach.isShow to new coach selected in state.resultSearchTrain
    //   */
    //   const clone = cloneDeep(state.coachSelectedArray)
    //   clone[state.tabIndex] = val
    //   state.coachSelectedArray = clone

    //   const trainId = state.trainSelectedArray[state.tabIndex].id
    //   const updateCoaches = trains => trains.map(train => {
    //     if (train.id === trainId) {
    //       return {
    //         ...train,
    //         coachOfTrains: train.coachOfTrains.map(coach => ({
    //           ...coach,
    //           isShow: coach.id === val.id,
    //         })),
    //       }
    //     }
    //     return train
    //   })
    //   if (state.tabIndex === 0) {
    //     state.resultSearchTrain.listTrain = updateCoaches(state.resultSearchTrain.listTrain)
    //   } else {
    //     state.resultSearchTrain.listTrainReturn = updateCoaches(state.resultSearchTrain.listTrainReturn)
    //   }
    // },

    // CHANGE_COACH(state, val) {
    //   const newCoachNumber = (state.coachSelectedArray[state.tabIndex].coachNumber + (val ? -1 : 1))
    //   const tem = state.trainSelectedArray[state.tabIndex].coachOfTrains.find(e => e.coachNumber === newCoachNumber)
    //   if (!tem) return
    //   const clone = cloneDeep(state.coachSelectedArray)
    //   clone[state.tabIndex] = tem
    //   state.coachSelectedArray = clone
    //   const trainId = state.trainSelectedArray[state.tabIndex].id
    //   const updateCoaches = trains => trains.map(train => {
    //     if (train.id === trainId) {
    //       return {
    //         ...train,
    //         coachOfTrains: train.coachOfTrains.map(coach => ({
    //           ...coach,
    //           isShow: coach.id === tem.id,
    //         })),
    //       }
    //     }
    //     return train
    //   })
    //   if (state.tabIndex === 0) {
    //     state.resultSearchTrain.listTrain = updateCoaches(state.resultSearchTrain.listTrain)
    //   } else {
    //     state.resultSearchTrain.listTrainReturn = updateCoaches(state.resultSearchTrain.listTrainReturn)
    //   }
    // },

    // SET_DATA_SEAT_BY_COACH(state, { trainId, coachId, seatLayoutData }) {
    //   const updateCoachSeatLayout = trains => trains.map(train => train.id !== trainId ? train : { ...train, coachOfTrains: train.coachOfTrains.map(coach => coach.id === coachId ? { ...coach, seatLayoutData } : coach) })

    //   const selectedTrain = state.trainSelectedArray[state.tabIndex]
    //   state.trainSelectedArray[state.tabIndex] = { ...selectedTrain, coachOfTrains: selectedTrain.coachOfTrains.map(coach => coach.id === coachId ? { ...coach, seatLayoutData } : coach) }

    //   const listTrainKey = state.tabIndex ? 'listTrainReturn' : 'listTrain'
    //   state.resultSearchTrain[listTrainKey] = updateCoachSeatLayout(state.resultSearchTrain[listTrainKey])
    // },

    SET_DATA_PROMOTION_BY_TRAIN(state, { trainId, promotionByTrain }) {
      const updatePromotionData = trains => {
        trains.forEach((train, index) => {
          if (train.id === trainId) {
            set(trains, index, {
              ...train,
              promotionByTrain,
            })
          }
        })
      }
      if (state.tabIndex === 0) {
        updatePromotionData(state.resultSearchTrain.listTrain)
      } else {
        updatePromotionData(state.resultSearchTrain.listTrainReturn)
      }
    },

    DELETE_TICKET(state, { storeTicketIndex, ticketDelete }) { // NOTE: ko delete theo index (case setInterval khi chọn vé)
      const clone = cloneDeep(state.ticketSelectedArray)
      clone[storeTicketIndex] = clone[storeTicketIndex].filter(t => !(t.trainId === ticketDelete.trainId
                                                                   && t.coachId === ticketDelete.coachId
                                                                   && t.seatId === ticketDelete.seatId))
      state.ticketSelectedArray = clone
    },

    ADD_TICKET(state, { storeTicketIndex, ticket }) {
      const clone = cloneDeep(state.ticketSelectedArray)
      clone[storeTicketIndex].push(ticket)
      state.ticketSelectedArray = clone
    },

    UPDATE_REF_TICKET(state, { type = 'add', storeTicketIndex, ticket }) {
      const refStoreIndex = storeTicketIndex ? 0 : 1
      const refTicket = type === 'add'
        ? state.ticketSelectedArray[refStoreIndex].find(tk => !tk.refTicketId)
        : state.ticketSelectedArray[refStoreIndex].find(tk => tk.refTicketId === ticket.ticketId)

      if (!refTicket) return

      const updateTicketRefs = (storeTicketArr, storeTkIndex) => {
        storeTicketArr.forEach(tk => {
          if (storeTkIndex === storeTicketIndex && tk.ticketId === ticket.ticketId && tk.seatId === ticket.seatId) {
            set(tk, 'refTicketId', refTicket.ticketId)

            // Update các thông tin liên quan khi có Ref
            set(tk, 'fullName', refTicket.fullName)
            set(tk, 'identifyNumber', refTicket.identifyNumber)
            set(tk, 'passengerTargetCode', refTicket.passengerTargetCode)
          }
          if (storeTkIndex === refStoreIndex && tk.ticketId === refTicket.ticketId && tk.seatId === refTicket.seatId) {
            set(tk, 'refTicketId', ticket.ticketId)
          }
        })
      }

      if (type === 'add') {
        state.ticketSelectedArray.forEach(updateTicketRefs)
      } else {
        state.ticketSelectedArray[refStoreIndex] = state.ticketSelectedArray[refStoreIndex].map(tk => {
          if (tk.ticketId === refTicket.ticketId) {
            set(tk, 'refTicketId', 0)
            del(tk, 'applyPromoData') // Xóa `applyPromoData` nếu không cần
            return tk
          }
          return tk
        })
      }
    },

    SET_EXPIRED_TICKET(state, { storeIndex, ticket }) {
      state.ticketSelectedArray[storeIndex] = state.ticketSelectedArray[storeIndex].map(tk => {
        if (tk.ticketId === ticket.ticketId) {
          set(tk, 'isExpired', true)
          return tk
        }
        return tk
      })
    },

    SET_DATA_APPLY_PROMOTION(state, data) {
      state.ticketSelectedArray.forEach((store, storeIndex) => {
        store.forEach((ticket, ticketIndex) => {
          const promo = data.find(p => p.ticketId === ticket.ticketId)
          if (promo) {
            set(state.ticketSelectedArray[storeIndex][ticketIndex], 'applyPromoData', promo)
          }
        })
      })
    },

    UPDATE_DATA_PAX(state, data) {
      const {
        storeTicketIndex,
        ticketId,
        field,
        value,
      } = data

      const clone = cloneDeep(state.ticketSelectedArray)
      clone[storeTicketIndex].forEach(ticket => {
        if (ticket.ticketId === ticketId) {
          ticket[field] = value
        }
      })
      state.ticketSelectedArray = clone
    },

    SET_DATA_DEFAULT_SERVICE_FEE(state, val) {
      state.defaultServicesFee = val
    },

    SET_SELECTED_PROFILE_SERVICE_FEE(state, val) {
      state.selectedProfileServiceFee = val
    },

    // ANCHOR BOOKING DETAIL
    SET_BOOKING_DATA(state, val) {
      state.bookingData = val
    },
  },

  actions: {
    async handleGetCategory({ commit }) {
      commit('SET_LOADING', { name: 'loadingCategory', value: true })
      return new Promise((resolve, reject) => {
        apiTrain.getCategory()
          .then(res => {
            commit('SET_CATEGORY', res.data)
            return resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('SET_LOADING', { name: 'loadingCategory', value: false })
          })
      })
    },
    async handleGetSetting({ commit }) {
      commit('SET_LOADING', { name: 'loadingSetting', value: true })
      return new Promise((resolve, reject) => {
        apiTrain.getSetting()
          .then(res => {
            commit('SET_SETTING', res.data)
            return resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('SET_LOADING', { name: 'loadingSetting', value: false })
          })
      })
    },
    async searchTrain({ getters, commit }, data) {
      commit('SET_LOADING', { name: 'loading', value: true })
      const payload = {
        ...data,
        bookingCode: getters.getBookingCode,
      }
      commit('SET_PAYLOAD_SEARCH', payload)
      return new Promise((resolve, reject) => {
        apiTrain
          .searchTrain(payload)
          .then(res => {
            commit('SET_DATA_SEARCH', res)
            commit('SET_INITIAL_SELECT_TRAIN')
            // commit('SET_INITIAL_SELECT_COACH')
            commit('SET_INITIAL_SELECT_TICKET')

            return resolve(res)
          })
          .catch(error => reject(error))
          .finally(() => {
            commit('SET_LOADING', { name: 'loading', value: false })
          })
      })
    },
    handleGetServicesFee({ commit }) {
      const defaultServiceFee = store.getters['userStore/getServiceFee'](true, 'TRAIN')
      if (defaultServiceFee) {
        commit('SET_DATA_DEFAULT_SERVICE_FEE', defaultServiceFee)
      }
    },
    // NOTE: storeIndex truyền vào: call fn deleteTicketHandle()
    async handleToggleSelectTicket({
      state, getters, dispatch, commit,
    }, { storeIndex = undefined, ticket }) {
      const storeTicketIndex = storeIndex ?? state.tabIndex
      const listTickets = state.ticketSelectedArray[storeTicketIndex]
      const index = listTickets.find(it => it.trainId === ticket.trainId
                                        && it.coachId === ticket.coachId
                                        && it.seatId === ticket.seatId)

      if (index) {
        const ticketDelete = {
          trainId: ticket.trainId,
          coachId: ticket.coachId,
          seatId: ticket.seatId,
          ticketId: ticket.ticketId,
        }
        await commit('DELETE_TICKET', { storeTicketIndex, ticketDelete })
        if (getters.getIsSearchRT) {
          // tìm và update data tìm vé của store index còn lại
          await commit('UPDATE_REF_TICKET', { type: 'delete', storeTicketIndex, ticket: ticketDelete })
        }
      } else {
        if (listTickets.length === getters.getMaxNumberOfTicketOW) {
          Vue.swal({
            title: '<span class="fw-700 text-warning">THÔNG BÁO</span>',
            html: `<p>Bạn đã giữ <span class="text-info fw-700">${getters.getMaxNumberOfTicketOW}</span> vé <span class="text-info fw-700"> chiều ${storeTicketIndex ? 'về' : 'đi'}</span>. Không thể yêu cầu thêm vé.</p>`,
            icon: 'warning',
            confirmButtonText: 'Đóng',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
          })
          return
        }
        await commit('ADD_TICKET', { storeTicketIndex, ticket })
        if (getters.getIsSearchRT) {
          await commit('UPDATE_REF_TICKET', { type: 'add', storeTicketIndex, ticket })
        }
      }

      if (!state.selectedProfileServiceFee && !state.defaultServicesFee) {
        dispatch('handleGetServicesFee')
      }

      dispatch('appliesPromotions') // NOTE đang call promo mỗi khi thêm hoặc xoá vé
    },
    // fetchSeatByCoach({ commit }, payload) {
    //   commit('SET_LOADING', { name: 'loadingGetSeatByCoach', value: true })
    //   return new Promise((resolve, reject) => {
    //     apiTrain.fetchSeatByCoach(payload)
    //       .then(res => {
    //         commit('SET_DATA_SEAT_BY_COACH', { trainId: payload.trainId, coachId: payload.coachId, seatLayoutData: res })
    //         resolve(res)
    //       })
    //       .catch(error => reject(error))
    //       .finally(() => {
    //         commit('SET_LOADING', { name: 'loadingGetSeatByCoach', value: false })
    //       })
    //   })
    // },
    autoPickTicket({ commit }, payload) {
      commit('SET_LOADING', { name: 'loadingPickTicket', value: true })
      return new Promise((resolve, reject) => {
        apiTrain.autoPickTicket(payload)
          .then(res => {
            // commit('SET_AUTO_PICK_TICKET', res)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('SET_LOADING', { name: 'loadingPickTicket', value: false })
          })
      })
    },
    saveBookings(ctx, payload) {
      return new Promise((resolve, reject) => {
        apiTrain.saveBookings(payload)
          .then(res =>
          // eslint-disable-next-line implicit-arrow-linebreak
            resolve(res))
          .catch(err => reject(err))
        // .finally(() => {
        //   commit('SET_LOADING', { name: 'loadingSaveBooking', value: false })
        // })
      })
    },
    searchPromotions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        apiTrain.searchPromotions(payload)
          .then(res => {
            commit('SET_DATA_PROMOTION_BY_TRAIN', { trainId: payload.trainId, promotionByTrain: res })
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },

    // ko truyền listTicketsId => call những vé ko có applyPromoData, có listTicketsId => call những vé chỉ định(case change paxTypeCode)
    async appliesPromotions({ state, getters, commit }, data) { // applyPromoData
      let listTicketNotPromo = []

      if (!isEmpty(data?.listTicketsIds)) {
        listTicketNotPromo = state.ticketSelectedArray.filter(store => store.length && store.filter(tk => data?.listTicketsIds.includes(tk.ticketId))).flat()
      } else {
        listTicketNotPromo = state.ticketSelectedArray.filter(store => store.length && store.filter(tk => !tk.applyPromoData)).flat()
      }

      if (!listTicketNotPromo.length) return

      if (data?.promotion) {
        listTicketNotPromo = listTicketNotPromo.map(tk => ({ ...tk, voucherCode: data?.promotion }))
      }

      const payload = {
        bookingCode: getters.getBookingCode,
        listPromotionTicketDetail: listTicketNotPromo,
      }

      commit('SET_LOADING', { name: 'loadingAppliesPromotions', value: true })

      await new Promise((resolve, reject) => {
        apiTrain.appliesPromotions(payload)
          .then(res => {
            const resPromoData = res?.calcListPromotion
            if (resPromoData?.length) commit('SET_DATA_APPLY_PROMOTION', resPromoData)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('SET_LOADING', { name: 'loadingAppliesPromotions', value: false })
          })
      })
    },

    checkAdultTicket(ctx, payload) {
      return new Promise((resolve, reject) => {
        apiTrain.checkAdultTicket(payload)
          .then(res =>
          // eslint-disable-next-line implicit-arrow-linebreak
            resolve(res))
          .catch(err => reject(err))
      })
    },

    setSelectedProfileServiceFee({ commit }, val) {
      commit('SET_SELECTED_PROFILE_SERVICE_FEE', { ...val })
    },

    // ANCHOR: BOOKINGS
    fetchBookingList(ctx, payload) {
      return apiTrain.fetchBookingList(payload)
    },

    // ANCHOR BOOKING DETAIL
    async getBookingById(ctx, payload) {
      const res = await apiTrain.getBookingById(payload)
      if (res?.data) {
        ctx.commit('SET_BOOKING_DATA', res.data)
      }
      return res
    },

    paymentTrains(ctx, payload) {
      return apiTrain.paymentTrains(payload)
    },

  },

}
