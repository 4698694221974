<template lang="">
  <b-table
    class="position-relative bg-white"
    :items="bookingData.bookingInfos"
    responsive
    primary-key="id"
    show-empty
    :empty-text="$t('noMatchingResult')"
    small
    hover
    bordered
    :fields="tableColumns"
  >
    <template
      v-for="column in tableColumns"
      #[`head(${column.key})`]
    >
      <span
        :key="column.key"
        class="text-nowrap"
      >
        {{ $t(`train.detail.bookingInfo.columns.${column.label}`) }}
      </span>
    </template>

    <template #table-busy>
      <div class="d-flex-center text-dark my-2 gap-2">
        <b-spinner class="align-middle" />
        <strong>{{ $t('loading') }}</strong>
      </div>
    </template>

    <template #cell(no)="data">
      <span class="font-weight-bold">{{ data.index + 1 }}</span>
    </template>

    <template #cell(fullName)="{ item }">
      <div class="font-weight-bolder text-nowrap">
        {{ item.fullName }}
      </div>
    </template>

    <template #cell(ticketId)="{ item }">
      <div class="text-nowrap">
        {{ item.ticketId }}
      </div>
    </template>

    <template #cell(identifyNumber)="{ item }">
      <div class="text-nowrap">
        {{ item.identifyNumber }}
      </div>
    </template>

    <template #cell(paxType)="{ item }">
      <div class="text-nowrap">
        {{ getPassengerTypeByPassengerTargetCode(item.passengerTargetCode) ?? item.passengerTargetCode }}
      </div>
    </template>

    <template #cell(seatType)="{ item }">
      <div>
        <div class="text-nowrap">
          {{ item.seatTypeName }}
        </div>
        <div
          v-if="item?.seatTypeCode"
          class="font-italic"
        >
          ({{ item?.seatTypeCode }})
        </div>
      </div>
    </template>

    <template #cell(trip)="{ item }">
      <div
        v-b-tooltip.window.hover="`${item.departureName} - ${item.arrivalName}`"
        class="text-nowrap"
      >
        {{ item.departure }} - {{ item.arrival }}
      </div>
    </template>

    <template #cell(ticketInfo)="{ item }">
      <div class="text-nowrap">
        <div class="fw-600">
          {{ item.trainCode }}
        </div>
        <div>{{ $t('train.coach') }}: <span class="fw-600">{{ item.coachName }}</span>, {{ $t('train.seatNumber') }}: <span class="fw-600">{{ item.seatNumber }}</span></div>
      </div>
    </template>

    <template #cell(departureTime)="{ item }">
      <div>
        <div>{{ item.departureHour }}</div>
        <div>{{ convertISODateTime(item.departureTime).dayAndMonth }}</div>
      </div>
    </template>

    <template #cell(arrivalTime)="{ item }">
      <div>
        <div>{{ item.arrivalHour }}</div>
        <div>{{ convertISODateTime(item.arrivalTime).dayAndMonth }}</div>
      </div>
    </template>

    <template #cell(totalPrice)="{ item }">
      <div class="text-nowrap fw-700">
        <div>{{ formatCurrency(showPriceTicket ? item?.totalPriceTicket : item?.totalPricePaid) }}</div>
      </div>
    </template>

    <template #cell(status)="{ item }">
      <div class="text-nowrap">
        <div>{{ item?.statusText }}</div>
        <div>{{ item?.status?.description }}</div>
      </div>
    </template>

    <template #cell(actions)="{ item }">
      <div class="d-flex-center justify-content-start gap-1">
        <BButton
          v-if="item.pdfUrl"
          v-b-tooltip.window.hover="$t('train.detail.bookingInfo.downloadTicket')"
          variant="flat-dark"
          class="p-50 rounded-circle"
          :href="item?.pdfUrl"
          target="_blank"
        >
          <IAmIcon
            icon="fileArrowDown"
            size="20"
          />
        </BButton>
      </div>
    </template>
  </b-table>
</template>
<script>
import { BButton, BTable } from 'bootstrap-vue'

import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'

import { getPassengerTypeByPassengerTargetCode } from '../../useTrainHandle'

export default {
  components: {
    BTable, BButton,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
    showPriceTicket: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const tableColumns = [
      { key: 'no', label: 'no' },
      { key: 'fullName', label: 'fullName' },
      { key: 'identifyNumber', label: 'identifyNumber' }, // CCCD
      { key: 'ticketId', label: 'ticketId' }, // CCCD
      { key: 'paxType', label: 'paxType' }, // loại khách
      { key: 'seatType', label: 'seatType' }, // loại ghế
      { key: 'trip', label: 'trip' }, // hành trình
      { key: 'ticketInfo', label: 'ticketInfo' }, // thông tin vé
      { key: 'departureTime', label: 'departureTime' }, // giờ đi
      { key: 'arrivalTime', label: 'arrivalTime' }, // giờ đến
      { key: 'totalPrice', label: 'totalPrice' }, // tổng tiền
      { key: 'status', label: 'status' }, // trạng thái
      { key: 'actions', label: 'actions' },
    ]
    return {
      formatCurrency,
      convertISODateTime,
      tableColumns,
      getPassengerTypeByPassengerTargetCode,
    }
  },
}
</script>
<style lang="">

</style>
