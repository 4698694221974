<template lang="">
  <b-modal
    id="modal-cancel-invoice"
    :title="$t('invoice.cancel.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    body-class="p-1"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    size="lg"
    @show="onShowHandle"
  >
    <IAmOverlay :loading="loading">
      <!-- info -->
      <BCard style="background: #e2e4e8">
        <BRow>
          <BCol
            cols="12"
            md="7"
            class="d-flex gap-2 flex-column"
          >
            <div class="d-flex-between">
              <div class="font-weight-bolder">
                {{ $t('invoice.columns.symbol') }}
              </div>
              <div>{{ invoiceData?.symbol }}</div>
            </div>
            <div class="d-flex-between">
              <div class="font-weight-bolder">
                {{ $t('invoice.columns.invNumber') }}
              </div>
              <div>{{ invoiceData?.invNumber }}</div>
            </div>
            <div class="d-flex-between">
              <div class="font-weight-bolder">
                {{ $t('invoice.columns.taxAuthorityCode') }}
              </div>
              <div>{{ invoiceData?.releasedInvCode }}</div>
            </div>
            <div class="d-flex-between">
              <div class="font-weight-bolder">
                {{ $t('invoice.columns.date') }}
              </div>
              <div>{{ convertISODateTime(invoiceData?.createdAt).date }}</div>
            </div>
          </BCol>
          <BCol
            cols="12"
            md="5"
            class="d-flex gap-2 flex-column justify-content-center text-right"
          >
            <div class="font-weight-bolder">
              {{ $t('invoice.totalPayment') }}:
            </div>
            <div class="font-weight-bolder">
              {{ formatCurrency(invoiceData?.totalAmount) }}
            </div>
            <!-- FIXME check theo field nao -->
            <div>
              <BBadge
                variant="light"
                pill
              >
                <div class="text-dark">
                  Chưa gửi TB sai sót đến CQT
                </div>
              </BBadge>
            </div>
          </BCol>
        </BRow>
      </BCard>

      <!-- form -->
      <validation-observer ref="refFormObserver">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('invoice.cancel.cancelDate')"
              vid="cancelDate"
            >
              <b-form-group label-for="cancelDate">
                <template #label>
                  {{ $t('invoice.cancel.cancelDate') }}
                  <span class="text-danger">*</span>
                </template>
                <flat-pickr
                  v-model="cancelInvoiceData.cancelDate"
                  class="d-none"
                />
                <div
                  class="p-0 form-control"
                  :class="errors[0] ? 'is-invalid' : ''"
                >
                  <flat-pickr
                    id="cancelDate"
                    v-model="cancelInvoiceData.cancelDate"
                    :name="$t('invoice.cancel.cancelDate')"
                    :config="{
                      ...configFlatPickr,
                      altInputClass: 'form-control border-transparent px-50 py-0 height-fit',
                    }"
                    :placeholder="$t('topup.placeholderSelectDate')"
                  />
                </div>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('invoice.cancel.reason')"
              vid="reason"
            >
              <b-form-group label-for="reason">
                <template #label>
                  {{ $t('invoice.cancel.reason') }}
                  <span class="text-danger">*</span>
                </template>
                <b-form-textarea
                  id="reason"
                  v-model.trim="cancelInvoiceData.reason"
                  rows="2"
                  max-rows="6"
                  :placeholder="$t('invoice.cancel.reason')"
                  style="overflow-y: auto"
                  :state="errors[0] ? false : null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('invoice.cancel.sendCancellationNotification')"
              vid="sendCancellationNotification"
            >
              <b-form-group>
                <b-form-checkbox
                  id="sendCancellationNotification"
                  v-model="cancelInvoiceData.sendCancellationNotification"
                  :placeholder="$t('invoice.cancel.sendCancellationNotification')"
                  style="overflow: auto"
                  :state="errors[0] ? false : null"
                  @change="onChangeSendCancellationNotificationHandle"
                >
                  {{ $t('invoice.cancel.sendCancellationNotification') }}
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              :rules="cancelInvoiceData?.sendCancellationNotification ? 'required' : ''"
              :name="$t('invoice.cancel.receiverName')"
              vid="receiverName"
            >
              <b-form-group label-for="receiverName">
                <template #label>
                  {{ $t('invoice.cancel.receiverName') }}
                  <span
                    v-if="cancelInvoiceData?.sendCancellationNotification"
                    class="text-danger"
                  >*</span>
                </template>
                <b-form-input
                  id="receiverName"
                  v-model.trim="cancelInvoiceData.receiverName"
                  :placeholder="$t('invoice.cancel.receiverName')"
                  style="overflow: auto"
                  :state="errors[0] ? false : null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex flex-column gap-1"
          >
            <div class="d-flex gap-1">
              <validation-provider
                #default="{ errors }"
                :rules="`email${cancelInvoiceData?.sendCancellationNotification ? '|required' : ''}`"
                :name="$t('invoice.cancel.email')"
                vid="email"
                class="flex-1"
              >
                <b-form-group label-for="email">
                  <template #label>
                    {{ $t('invoice.cancel.email') }}
                    <span
                      v-if="cancelInvoiceData?.sendCancellationNotification"
                      class="text-danger"
                    >*</span>
                  </template>
                  <b-form-input
                    id="email"
                    v-model.trim="cancelInvoiceData.email"
                    :placeholder="$t('invoice.cancel.email')"
                    style="overflow: auto"
                    :state="errors[0] ? false : null"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <div style="padding-top: 25px">
                <BButton
                  id="cc-button"
                  v-b-tooltip.top.hover.window="$t('invoice.cancel.addCc')"
                  class="p-50"
                  variant="flat-primary"
                >
                  CC
                </BButton>
                <InvoiceAddCcPopover
                  target="cc-button"
                  type="cc"
                  @push="val => onAddCcHandle('cc', val)"
                />
                |
                <BButton
                  id="bcc-button"
                  v-b-tooltip.top.hover.window="$t('invoice.cancel.addBcc')"
                  class="p-50"
                  variant="flat-primary"
                >
                  BCC
                </BButton>
                <InvoiceAddCcPopover
                  target="bcc-button"
                  type="bcc"
                  @push="val => onAddCcHandle('bcc', val)"
                />
              </div>
            </div>
            <div v-if="cancelInvoiceData?.cc && cancelInvoiceData.cc.length">
              CC:
              <BBadge
                v-for="(ccItem, ccIndex) in cancelInvoiceData.cc"
                :key="ccIndex"
                variant="light-info"
                pills
                class="mx-25"
              >
                <div class="d-flex-center gap-1">
                  <span>{{ ccItem }}</span>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="removeCcItem('cc', ccItem)"
                  />
                </div>
              </BBadge>
            </div>
            <div v-if="cancelInvoiceData?.bcc && cancelInvoiceData.bcc.length">
              BCC:
              <BBadge
                v-for="(bccItem, bccIndex) in cancelInvoiceData.bcc"
                :key="bccIndex"
                variant="light-info"
                pills
                class="mx-25"
              >
                <div class="d-flex-center gap-1">
                  <span>{{ bccItem }}</span>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="removeCcItem('bcc', bccItem)"
                  />
                </div>
              </BBadge>
            </div>
          </b-col>
        </b-row>
        <!-- ANCHOR BUTTONS -->
        <div class="d-flex-center gap-2 mt-1">
          <BButton
            variant="secondary"
            class="flex-1"
            @click="onCancelModal"
          >
            {{ $t('cancel') }}
          </BButton>
          <BButton
            variant="primary"
            class="flex-1"
            @click="onSubmitHandle"
          >
            {{ $t('invoice.cancel.submit') }}
          </BButton>
        </div>
      </validation-observer>
    </IAmOverlay>
  </b-modal>
</template>
<script>
import {
  BModal, BCard, BRow, BCol,
  BBadge,
  BFormInvalidFeedback,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { ref, computed } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import VueI18n from '@/libs/i18n'
import useInvoiceHandle from '@/views/invoices/useInvoiceHandle'

import { convertISODateTime, formatCurrency, getToday } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    flatPickr: () => import('vue-flatpickr-component'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    InvoiceAddCcPopover: () => import('@/views/invoices/components/InvoiceAddCcPopover.vue'),
  },
  props: {
    invoiceData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { root, emit }) {
    const { refFormObserver } = formValidation()
    const { cancelInvoice, loading } = useInvoiceHandle()

    const blankCancelInvoiceData = {
      reason: null,
      sendCancellationNotification: false,
      receiverName: null,
      email: null,
      cc: [],
      bcc: [],
      cancelDate: getToday('YYYY-MM-DD'),
    }

    const cancelInvoiceData = ref()

    function onShowHandle() {
      cancelInvoiceData.value = cloneDeep(blankCancelInvoiceData)
    }

    const locale = computed(() => VueI18n.locale).value

    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        // minDate: 'today',
        // maxDate: 'today',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: false,
        disableMobile: true,
      },
    )
    function onCancelModal() {
      root.$bvModal.hide('modal-cancel-invoice')
    }

    async function onSubmitHandle() {
      const valid = await refFormObserver.value.validate()
      if (!valid) return
      const timeNow = getToday(null).slice(10)
      const payload = {
        ...cancelInvoiceData.value,
        cancelDate: `${cancelInvoiceData.value.cancelDate}${timeNow}`,
        cc: cancelInvoiceData.value.cc.join(';'),
        bcc: cancelInvoiceData.value.bcc.join(';'),
      }
      try {
        await cancelInvoice(props.invoiceData.id, payload)
      } finally {
        emit('refetch')
        onCancelModal()
      }
    }

    function onAddCcHandle(type, email) {
      if (!cancelInvoiceData.value[type].includes(email)) cancelInvoiceData.value[type].push(email)
    }
    function removeCcItem(type, item) {
      cancelInvoiceData.value[type] = cancelInvoiceData.value?.[type].filter(i => i !== item)
    }

    function onChangeSendCancellationNotificationHandle(val) {
      if (val) {
        cancelInvoiceData.value.receiverName = props.invoiceData?.buyerName
        cancelInvoiceData.value.email = props.invoiceData?.buyerEmail
      } else {
        if (cancelInvoiceData.value.receiverName === props.invoiceData?.buyerName) cancelInvoiceData.value.receiverName = ''
        if (cancelInvoiceData.value.email === props.invoiceData?.buyerEmail) cancelInvoiceData.value.email = ''
      }
    }

    return {
      onShowHandle,
      convertISODateTime,
      formatCurrency,
      refFormObserver,
      Vietnamese,
      cancelInvoiceData,
      configFlatPickr,
      onCancelModal,
      onSubmitHandle,
      loading,
      onAddCcHandle,
      removeCcItem,
      onChangeSendCancellationNotificationHandle,
    }
  },

}
</script>
<style lang="scss" scoped>
.form-control ::v-deep {
  &.height-fit {
    height: 37px;
  }
}
</style>
