var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-topup-confirm-manual",
      "size": "lg",
      "centered": "",
      "header-bg-variant": "light-info",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "title": _vm.$t('topup.confirmManual'),
      "ok-title": _vm.$t('topup.confirm'),
      "ok-disabled": !_vm.payAmountTemp || _vm.loading,
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "hidden": _vm.onHiddenTopupConfirm,
      "ok": function ok($event) {
        _vm.onConfirmManual($event, _vm.payCode, _vm.payAmount, _vm.payType === 'PAYMENT' ? _vm.autoPayment : undefined, _vm.callbackOnConfirmSuccess);
      }
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !!_vm.loading
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.tableColumns.paymentCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.payCode,
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.paymentAmount')
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('topup.paymentAmount')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-input-group', {
          staticClass: "flex-nowrap",
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('b-input-group-text', [_vm._v(" " + _vm._s(_vm.$t('agency.currency')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('IAmInputMoney', {
          staticClass: "flex-grow-1",
          attrs: {
            "value-money": _vm.payAmountTemp,
            "state": errors[0] ? false : null
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              _vm.payAmountTemp = $event;
            },
            "update:value-money": function updateValueMoney($event) {
              _vm.payAmountTemp = $event;
            }
          }
        })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])];
      }
    }])
  })], 1)], 1), _vm.payType === 'PAYMENT' ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.confirmAutoPayment')
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "auto-payment-radios",
      "options": _vm.autoPaymentOptions,
      "name": "radios-stacked",
      "stacked": ""
    },
    model: {
      value: _vm.autoPayment,
      callback: function callback($$v) {
        _vm.autoPayment = $$v;
      },
      expression: "autoPayment"
    }
  })], 1)], 1) : _vm._e()], 1), _c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('topup.uploadEvidence')) + " ")];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    ref: "refProviderImgFile",
    attrs: {
      "rules": "",
      "name": _vm.$t('topup.uploadEvidence')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-file', {
          attrs: {
            "accept": "image/*",
            "state": errors[0] ? false : null,
            "placeholder": _vm.$t('topup.placeholderChooseImage'),
            "drop-placeholder": _vm.$t('topup.placeholderDropImage')
          },
          on: {
            "change": _vm.onImgFileChange
          },
          model: {
            value: _vm.imgFile,
            callback: function callback($$v) {
              _vm.imgFile = $$v;
            },
            expression: "imgFile"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])];
      }
    }])
  })], 1)], 1), _vm.imgBlobUrl ? _c('b-col', {
    attrs: {
      "cols": "10",
      "offset": "1"
    }
  }, [_c('b-img', {
    staticClass: "border shadow-lg mb-1",
    staticStyle: {
      "max-height": "600px"
    },
    attrs: {
      "src": _vm.imgBlobUrl,
      "fluid": "",
      "center": "",
      "alt": "Evidence Image"
    }
  })], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('topup.note')) + " ")];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "rules": "",
      "name": _vm.$t('topup.note')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-textarea', {
          staticClass: "overflow-auto",
          attrs: {
            "no-resize": "",
            "max-rows": "4",
            "trim": "",
            "rows": ['xs', 'sm'].includes(_vm.$store.getters['app/currentBreakPoint']) ? 1 : 2,
            "state": errors[0] ? false : null,
            "placeholder": _vm.$t('topup.note')
          },
          model: {
            value: _vm.note,
            callback: function callback($$v) {
              _vm.note = $$v;
            },
            expression: "note"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }