var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "search-train"
    }
  }, [_c('v-select', {
    ref: "vSelectRef",
    attrs: {
      "value": _vm.valueProp,
      "calculate-position": _vm.withPopper,
      "clearable": false,
      "options": _vm.getListTrainStation,
      "filter": _vm.filterFn,
      "label": "code"
    },
    on: {
      "option:selected": _vm.handleChooseAirport
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "fw-800 font-medium-4"
        }, [_c('span', {}, [_vm._v(" " + _vm._s(data.name) + " ")]), _c('span', {
          staticClass: "text-success ml-50"
        }, [_vm._v(_vm._s(data.code))])])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex justify-content-start text-truncate"
        }, [_c('strong', {
          staticClass: "mr-1 fw-700"
        }, [_vm._v(" " + _vm._s(data.name) + " ")]), _c('strong', {
          staticClass: "text-success"
        }, [_vm._v("( " + _vm._s(data.code) + " )")])])];
      }
    }, {
      key: "no-options",
      fn: function fn(_ref) {
        var search = _ref.search,
          searching = _ref.searching;
        return [searching ? [_vm._v(" " + _vm._s(_vm.$t('flight.noResultFound')) + " "), _c('span', {
          staticClass: "font-medium-3 fw-700"
        }, [_vm._v(_vm._s(search))])] : !search ? [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")] : _vm.stateLoadingCategory ? [_c('div', {
          staticClass: "d-fex-center"
        }, [_c('span', {
          staticClass: "mr-50"
        }, [_vm._v(_vm._s(_vm.$t('train.search.loadingData')))]), _c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1)] : _vm._e()];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }