var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "mb-0",
    attrs: {
      "id": "invoice-ticket-form",
      "body-class": "p-50"
    }
  }, [_c('b-row', {
    staticClass: "px-75"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.invoiceTickets.fields.bookingCode'),
      "rules": "",
      "vid": "bookingCode"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.bookingCode')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "bookingCode",
            "value": _vm.invoiceTicket.bookingCode,
            "placeholder": _vm.$t('invoice.invoiceTickets.fields.bookingCode'),
            "state": errors[0] ? false : null,
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": !_vm.isEditing
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceTicket', Object.assign({}, _vm.invoiceTicket, {
                bookingCode: val
              }));
            }
          }
        }), _c('BFormInvalidFeedback', [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.invoiceTickets.fields.ticketNumber'),
      "rules": "required",
      "vid": "ticketNumber"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.ticketNumber')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "ticketNumber",
            "value": _vm.invoiceTicket.ticketNumber,
            "placeholder": _vm.$t('invoice.invoiceTickets.fields.ticketNumber'),
            "state": errors[0] ? false : null,
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": !_vm.isEditing
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceTicket', Object.assign({}, _vm.invoiceTicket, {
                ticketNumber: val
              }));
            }
          }
        }), _c('BFormInvalidFeedback', [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.invoiceTickets.fields.customerName'),
      "rules": "",
      "vid": "customerName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.customerName')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "customerName",
            "value": _vm.invoiceTicket.customerName,
            "placeholder": _vm.$t('invoice.invoiceTickets.fields.customerName'),
            "state": errors[0] ? false : null,
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": !_vm.isEditing
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceTicket', Object.assign({}, _vm.invoiceTicket, {
                customerName: val
              }));
            }
          }
        }), _c('BFormInvalidFeedback', [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.invoiceTickets.fields.flightTrip'),
      "rules": "",
      "vid": "flightTrip"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.flightTrip')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "flightTrip",
            "value": _vm.invoiceTicket.flightTrip,
            "placeholder": _vm.$t('invoice.invoiceTickets.fields.flightTrip'),
            "state": errors[0] ? false : null,
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": !_vm.isEditing
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceTicket', Object.assign({}, _vm.invoiceTicket, {
                flightTrip: val
              }));
            }
          }
        }), _c('BFormInvalidFeedback', [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('invoice.invoiceTickets.fields.flightDate'),
      "vid": "flightDate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.flightDate')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "d-none",
          model: {
            value: _vm.invoiceTicket.flightDate,
            callback: function callback($$v) {
              _vm.$set(_vm.invoiceTicket, "flightDate", $$v);
            },
            expression: "invoiceTicket.flightDate"
          }
        }), _vm.isEditing ? _c('div', {
          staticClass: "form-control p-0",
          class: errors[0] ? 'is-invalid' : ''
        }, [_c('flat-pickr', {
          staticClass: "form-control px-50 py-0",
          attrs: {
            "id": "flightDate",
            "name": _vm.$t('invoice.invoiceTickets.fields.flightDate'),
            "config": Object.assign({}, _vm.configFlatPickr, {
              altInputClass: 'form-control input px-1 py-0 border-transparent height-25rem'
            }),
            "placeholder": _vm.$t('invoice.invoiceTickets.fields.flightDate')
          },
          model: {
            value: _vm.invoiceTicket.flightDate,
            callback: function callback($$v) {
              _vm.$set(_vm.invoiceTicket, "flightDate", $$v);
            },
            expression: "invoiceTicket.flightDate"
          }
        })], 1) : _c('b-form-input', {
          attrs: {
            "disabled": "",
            "value": _vm.invoiceTicket.flightDate ? _vm.formatDateMonth(_vm.invoiceTicket.flightDate, 'DD-MM-YYYY') : '',
            "placeholder": _vm.$t('invoice.invoiceTickets.fields.flightDate')
          }
        }), _c('BFormInvalidFeedback', [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.invoiceTickets.fields.invoiceTicketType'),
      "rules": "required",
      "vid": "invoiceTicketType"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "invoiceTicketType"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.invoiceTicketType')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "w-100",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "invoiceTicketType",
            "value": _vm.invoiceTicket.type,
            "name": _vm.$t('invoice.invoiceTickets.fields.invoiceTicketType'),
            "options": _vm.INVOICE_TICKET_TYPE_OPTIONS,
            "label": "label",
            "disabled": !_vm.isEditing,
            "clearable": false,
            "reduce": function reduce(val) {
              return val.value;
            },
            "placeholder": _vm.$t('placeholderSelect')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceTicket', Object.assign({}, _vm.invoiceTicket, {
                type: val
              }));
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('BBadge', {
                staticClass: "px-75",
                attrs: {
                  "variant": _vm.resolveColorOfInvoiceTicketType(data.label) || 'secondary',
                  "pill": ""
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('BBadge', {
                staticClass: "px-75",
                attrs: {
                  "variant": _vm.resolveColorOfInvoiceTicketType(data.label) || 'secondary',
                  "pill": ""
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(data.label))) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), _c('BFormInvalidFeedback', [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1)], 1), _c('b-row', {
    staticClass: "px-75"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.invoiceTickets.fields.totalAmount'),
      "rules": "required",
      "vid": "totalAmount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.totalAmount')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticClass: "d-none",
          attrs: {
            "id": "totalAmount",
            "value": _vm.invoiceTicket.unitPrice,
            "disabled": !_vm.isEditing
          }
        }), _c('IAmInputMoney', {
          staticClass: "flex-grow-1",
          attrs: {
            "value-money": _vm.invoiceTicket.unitPrice,
            "state": errors[0] ? false : null,
            "disabled": !_vm.isEditing,
            "name": _vm.$t('invoice.invoiceTickets.fields.totalAmount'),
            "can-input-negative": true,
            "placeholder": _vm.$t('invoice.invoiceTickets.fields.totalAmount')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceTicket', Object.assign({}, _vm.invoiceTicket, {
                unitPrice: val
              }));
            }
          }
        }), _c('small', {
          staticClass: "text-danger text-12px"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.invoiceTickets.fields.valueAddedTax'),
      "rules": "required",
      "vid": "valueAddedTax"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "valueAddedTax"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.valueAddedTax')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "w-100",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "valueAddedTax",
            "value": _vm.invoiceTicket.valueAddedTax,
            "name": _vm.$t('invoice.invoiceTickets.fields.valueAddedTax'),
            "options": _vm.TAX_TYPES,
            "label": "label",
            "disabled": !_vm.isEditing,
            "clearable": false,
            "reduce": function reduce(val) {
              return val.label;
            },
            "placeholder": _vm.$t('placeholderSelect')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceTicket', Object.assign({}, _vm.invoiceTicket, {
                valueAddedTax: val
              }));
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(_vm.$t("invoice.taxTypes.".concat(data.label))) + " ")];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(_vm.$t("invoice.taxTypes.".concat(data.label))) + " ")];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), _c('BFormInvalidFeedback', [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.invoiceTickets.fields.collectionFee'),
      "rules": "required",
      "vid": "collectionFee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.fields.collectionFee')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticClass: "d-none",
          attrs: {
            "id": "collectionFee",
            "value": _vm.invoiceTicket.collectionFee,
            "disabled": !_vm.isEditing
          }
        }), _c('IAmInputMoney', {
          staticClass: "flex-grow-1",
          attrs: {
            "value-money": _vm.invoiceTicket.collectionFee,
            "state": errors[0] ? false : null,
            "disabled": !_vm.isEditing,
            "name": _vm.$t('invoice.invoiceTickets.fields.collectionFee'),
            "can-input-negative": true,
            "placeholder": _vm.$t('invoice.invoiceTickets.fields.collectionFee')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceTicket', Object.assign({}, _vm.invoiceTicket, {
                collectionFee: val
              }));
            }
          }
        }), _c('small', {
          staticClass: "text-danger text-12px"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }