<!-- eslint-disable vue/no-mutating-props -->
<template lang="">
  <!-- SECTION Thông tin xuất hoá đơn -->
  <BCard
    id="invoice-ticket-form"
    body-class="p-50"
    class="mb-0"
  >
    <!-- <pre>{{ invoiceTicket }}</pre> -->
    <b-row class="px-75">
      <!-- ANCHOR Booking code -->
      <b-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.invoiceTickets.fields.bookingCode')"
          rules=""
          vid="bookingCode"
        >
          <b-form-group>
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.bookingCode') }}
              <!-- <span class="text-danger">(*)</span> -->
            </template>
            <b-form-input
              id="bookingCode"
              :value="invoiceTicket.bookingCode"
              :placeholder="$t('invoice.invoiceTickets.fields.bookingCode')"
              :state="errors[0] ? false : null"
              :formatter="onlyUpperCaseFormatter"
              :disabled="!isEditing"
              @input="(val) =>$emit('update:invoiceTicket', { ...invoiceTicket, bookingCode: val })"
            />
            <BFormInvalidFeedback>{{ errors[0] }}</BFormInvalidFeedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Ticket number -->
      <b-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.invoiceTickets.fields.ticketNumber')"
          rules="required"
          vid="ticketNumber"
        >
          <b-form-group>
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.ticketNumber') }}
              <span class="text-danger">(*)</span>
            </template>
            <b-form-input
              id="ticketNumber"
              :value="invoiceTicket.ticketNumber"
              :placeholder="$t('invoice.invoiceTickets.fields.ticketNumber')"
              :state="errors[0] ? false : null"
              :formatter="onlyUpperCaseFormatter"
              :disabled="!isEditing"
              @input="(val) =>$emit('update:invoiceTicket', { ...invoiceTicket, ticketNumber: val })"
            />
            <BFormInvalidFeedback>{{ errors[0] }}</BFormInvalidFeedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Customer name -->
      <b-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.invoiceTickets.fields.customerName')"
          rules=""
          vid="customerName"
        >
          <b-form-group>
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.customerName') }}
              <!-- <span class="text-danger">(*)</span> -->
            </template>
            <b-form-input
              id="customerName"
              :value="invoiceTicket.customerName"
              :placeholder="$t('invoice.invoiceTickets.fields.customerName')"
              :state="errors[0] ? false : null"
              :formatter="onlyUpperCaseFormatter"
              :disabled="!isEditing"
              @input="(val) =>$emit('update:invoiceTicket', { ...invoiceTicket, customerName: val })"
            />
            <BFormInvalidFeedback>{{ errors[0] }}</BFormInvalidFeedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Flight trip -->
      <b-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.invoiceTickets.fields.flightTrip')"
          rules=""
          vid="flightTrip"
        >
          <b-form-group>
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.flightTrip') }}
              <!-- <span class="text-danger">(*)</span> -->
            </template>
            <b-form-input
              id="flightTrip"
              :value="invoiceTicket.flightTrip"
              :placeholder="$t('invoice.invoiceTickets.fields.flightTrip')"
              :state="errors[0] ? false : null"
              :formatter="onlyUpperCaseFormatter"
              :disabled="!isEditing"
              @input="(val) =>$emit('update:invoiceTicket', { ...invoiceTicket, flightTrip: val })"
            />
            <BFormInvalidFeedback>{{ errors[0] }}</BFormInvalidFeedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Flight date -->
      <b-col
        cols="12"
        md="4"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('invoice.invoiceTickets.fields.flightDate')"
          vid="flightDate"
        >
          <b-form-group>
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.flightDate') }}
              <span class="text-danger">(*)</span>
            </template>
            <flat-pickr
              v-model="invoiceTicket.flightDate"
              class="d-none"
            />
            <div
              v-if="isEditing"
              class="form-control p-0"
              :class="errors[0] ? 'is-invalid' : ''"
            >
              <flat-pickr
                id="flightDate"
                v-model="invoiceTicket.flightDate"
                :name="$t('invoice.invoiceTickets.fields.flightDate')"
                class="form-control px-50 py-0"
                :config="{
                  ...configFlatPickr,
                  altInputClass: 'form-control input px-1 py-0 border-transparent height-25rem',
                }"
                :placeholder="$t('invoice.invoiceTickets.fields.flightDate')"
              />
            </div>
            <b-form-input
              v-else
              disabled
              :value="invoiceTicket.flightDate ? formatDateMonth(invoiceTicket.flightDate, 'DD-MM-YYYY') : ''"
              :placeholder="$t('invoice.invoiceTickets.fields.flightDate')"
            />
            <BFormInvalidFeedback>{{ errors[0] }}</BFormInvalidFeedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- ANCHOR type -->
      <b-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.invoiceTickets.fields.invoiceTicketType')"
          rules="required"
          vid="invoiceTicketType"
        >
          <b-form-group
            label-for="invoiceTicketType"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.invoiceTicketType') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="invoiceTicketType"
              :value="invoiceTicket.type"
              :name="$t('invoice.invoiceTickets.fields.invoiceTicketType')"
              class="w-100"
              style="font-size: 1rem"
              :options="INVOICE_TICKET_TYPE_OPTIONS"
              label="label"
              :disabled="!isEditing"
              :clearable="false"
              :reduce="val => val.value"
              :placeholder="$t('placeholderSelect')"
              @input="(val) => $emit('update:invoiceTicket', { ...invoiceTicket, type: val })"
            >
              <template #option="data">
                <BBadge
                  :variant="resolveColorOfInvoiceTicketType(data.label) || 'secondary'"
                  pill
                  class="px-75"
                >
                  {{ $t(`invoice.invoiceTickets.type.${data.label}`) }}
                </BBadge>
              </template>

              <template #selected-option="data">
                <BBadge
                  :variant="resolveColorOfInvoiceTicketType(data.label) || 'secondary'"
                  pill
                  class="px-75"
                >
                  {{ $t(`invoice.invoiceTickets.type.${data.label}`) }}
                </BBadge>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <BFormInvalidFeedback>{{ errors[0] }}</BFormInvalidFeedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>

    <b-row class="px-75">
      <!-- ANCHOR totalAmount -->
      <b-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.invoiceTickets.fields.totalAmount')"
          rules="required"
          vid="totalAmount"
        >
          <b-form-group>
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.totalAmount') }}
              <span class="text-danger">(*)</span>
            </template>
            <b-form-input
              id="totalAmount"
              :value="invoiceTicket.unitPrice"
              class="d-none"
              :disabled="!isEditing"
            />
            <IAmInputMoney
              :value-money="invoiceTicket.unitPrice"
              class="flex-grow-1"
              :state="errors[0] ? false : null"
              :disabled="!isEditing"
              :name="$t('invoice.invoiceTickets.fields.totalAmount')"
              :can-input-negative="true"
              :placeholder="$t('invoice.invoiceTickets.fields.totalAmount')"
              @input="(val) => $emit('update:invoiceTicket', { ...invoiceTicket, unitPrice: val })"
            />
            <small class="text-danger text-12px">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR VAT -->
      <b-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.invoiceTickets.fields.valueAddedTax')"
          rules="required"
          vid="valueAddedTax"
        >
          <b-form-group
            label-for="valueAddedTax"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.valueAddedTax') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="valueAddedTax"
              :value="invoiceTicket.valueAddedTax"
              :name="$t('invoice.invoiceTickets.fields.valueAddedTax')"
              class="w-100"
              style="font-size: 1rem"
              :options="TAX_TYPES"
              label="label"
              :disabled="!isEditing"
              :clearable="false"
              :reduce="val => val.label"
              :placeholder="$t('placeholderSelect')"
              @input="(val) => $emit('update:invoiceTicket', { ...invoiceTicket, valueAddedTax: val })"
            >
              <template #option="data">
                {{ $t(`invoice.taxTypes.${data.label}`) }}
              </template>
              <template #selected-option="data">
                {{ $t(`invoice.taxTypes.${data.label}`) }}
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <BFormInvalidFeedback>{{ errors[0] }}</BFormInvalidFeedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR collectionFee -->
      <b-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.invoiceTickets.fields.collectionFee')"
          rules="required"
          vid="collectionFee"
        >
          <b-form-group>
            <template #label>
              {{ $t('invoice.invoiceTickets.fields.collectionFee') }}
              <span class="text-danger">(*)</span>
            </template>
            <b-form-input
              id="collectionFee"
              :value="invoiceTicket.collectionFee"
              class="d-none"
              :disabled="!isEditing"
            />
            <IAmInputMoney
              :value-money="invoiceTicket.collectionFee"
              class="flex-grow-1"
              :state="errors[0] ? false : null"
              :disabled="!isEditing"
              :name="$t('invoice.invoiceTickets.fields.collectionFee')"
              :can-input-negative="true"
              :placeholder="$t('invoice.invoiceTickets.fields.collectionFee')"
              @input="(val) => $emit('update:invoiceTicket', { ...invoiceTicket, collectionFee: val })"
            />
            <small class="text-danger text-12px">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
  </BCard>
  <!-- !SECTION -->
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BBadge,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { ValidationProvider } from 'vee-validate'
import { computed, ref } from '@vue/composition-api'

import VueI18n from '@/libs/i18n'
import { formatDateMonth } from '@/@core/utils/filter'
import { TAX_TYPES } from '@/constants/invoice'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import { required } from '@validations'

import { INVOICE_TICKET_TYPE_OPTIONS, resolveColorOfInvoiceTicketType } from './useInvoiceTicketHandle'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    vSelect: () => import('vue-select'),
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    BBadge,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
  },
  props: {
    isEditing: { type: Boolean, default: true },
    invoiceTicket: { type: [Object, null], default: null },
  },
  setup() {
    const locale = computed(() => VueI18n.locale).value

    const configFlatPickr = ref({
      dateFormat: 'Y-m-d',
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    })
    return {
      onlyUpperCaseFormatter,
      INVOICE_TICKET_TYPE_OPTIONS,
      resolveColorOfInvoiceTicketType,
      required,
      configFlatPickr,
      formatDateMonth,
      TAX_TYPES,
    }
  },
}
</script>
<style lang="scss" scoped>
#invoice-ticket-form ::v-deep {
  .form-group {
    // margin-bottom: 0.25rem;
    label,
    legend {
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 1rem;
      // white-space: nowrap;
    }
  }
}
</style>
