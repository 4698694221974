import { getMoment } from '@/@core/utils/filter'
import { apiSchedule } from '@/api/'

export default {
  namespaced: true,
  state: {
    workDate: getMoment(new Date()).format('YYYY-MM-DD'),
    scheduleType: 0,
    employeeType: 0,
  },
  getters: {
    getWorkDate: state => state.workDate,
    getScheduleType: state => state.scheduleType,
    getEmployeeType: state => state.employeeType,
  },
  mutations: {
    SET_WORK_DATE(state, val) {
      state.workDate = val
    },
    SET_SCHEDULE_TYPE(state, val) {
      state.scheduleType = val
    },
    SET_EMPLOYEE_TYPE(state, val) {
      state.employeeType = val
    },
  },
  actions: {
    fetchSchedules(ctx, params) {
      return apiSchedule.fetchSchedules(params)
    },
    fetchEmployeesHasSchedules(ctx, params) {
      return apiSchedule.fetchEmployeesHasSchedules(params)
    },
    bulkInsertSchedule(ctx, body) {
      return apiSchedule.bulkInsertSchedule(body)
    },
    setWorkDate({ commit }, val) {
      commit('SET_WORK_DATE', val)
    },
    setScheduleType({ commit }, val) {
      commit('SET_SCHEDULE_TYPE', val)
    },
    setEmployeeType({ commit }, val) {
      commit('SET_EMPLOYEE_TYPE', val)
    },
  },
}
