import { apiTopup, apiGeneral } from '@/api/'

export default {
  namespaced: true,

  state: {
    banks: [],
    topupQrCode: '',
  },

  mutations: {
    SET_BANKS(state, data) {
      state.banks = data
    },

    SET_TOPUP_QRCODE(state, data) {
      state.topupQrCode = data
    },
  },

  actions: {
    async fetchBanks({ state, commit }) {
      if (state.banks.length) return state.banks

      const res = await apiTopup.fetchBanks()
      commit('SET_BANKS', res?.data || [])

      return res
    },

    async fetchTopupQrCode({ commit }, payload) {
      const res = await apiTopup.fetchTopupQrCode(payload)
      commit('SET_TOPUP_QRCODE', res?.qrCode || '')

      return res
    },

    removeTopupQrCode({ commit }) {
      commit('SET_TOPUP_QRCODE', '')
    },

    async confirmTopup(_ctx, payload) {
      const res = await apiTopup.confirmTopup(payload)
      return res
    },

    async confirmTopupManual(_ctx, payload) {
      return apiTopup.confirmTopupManual(payload)
    },

    async fetchTopups(_ctx, params) {
      const res = await apiTopup.fetchTopups(params)
      return res
    },

    async getTopupById(_ctx, { id }) {
      const res = await apiTopup.getTopupById(id)
      return res
    },

    async getTopupByCode(_ctx, { code }) {
      const res = await apiTopup.getTopupByCode(code)
      return res
    },

    async exportTopups(_ctx, payload, fileName = 'Topups-List') {
      const res = await apiTopup.exportTopups(payload, fileName)
      return res
    },

    async uploadEvidenceImage(_ctx, formData = new FormData()) {
      const res = await apiGeneral.uploadImage(formData)
      return res
    },
  },
}
