<template>
  <b-modal
    id="id-topup-confirm-manual"
    size="lg"
    centered
    header-bg-variant="light-info"
    no-close-on-backdrop
    no-close-on-esc
    :title="$t('topup.confirmManual')"
    :ok-title="$t('topup.confirm')"
    :ok-disabled="!payAmountTemp || loading"
    :cancel-title="$t('cancel')"
    @hidden="onHiddenTopupConfirm"
    @ok="onConfirmManual(
      $event,
      payCode,
      payAmount,
      (payType === 'PAYMENT' ? autoPayment : undefined),
      callbackOnConfirmSuccess,
    )"
  >
    <IAmOverlay :loading="!!loading">
      <b-form>
        <b-row>
          <!-- ANCHOR Payment Code -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="font-weight-bold"
              :label="$t('topup.tableColumns.paymentCode')"
            >
              <b-form-input
                :value="payCode"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR Payment Amount -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="font-weight-bold"
              :label="$t('topup.paymentAmount')"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('topup.paymentAmount')"
              >
                <b-input-group class="flex-nowrap">
                  <IAmInputMoney
                    :value-money.sync="payAmountTemp"
                    class="flex-grow-1"
                    :state="errors[0] ? false : null"
                  />
                  <template #append>
                    <b-input-group-text>
                      {{ $t('agency.currency') }}
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Auto Payment  -->
          <b-col
            v-if="payType === 'PAYMENT'"
            cols="12"
            md="6"
          >
            <b-form-group
              class="font-weight-bold"
              :label="$t('topup.confirmAutoPayment')"
            >
              <b-form-radio-group
                id="auto-payment-radios"
                v-model="autoPayment"
                :options="autoPaymentOptions"
                name="radios-stacked"
                stacked
              />
            </b-form-group>
          </b-col>
        </b-row>

        <validation-observer ref="refFormObserver">
          <b-row>
            <!-- ANCHOR Upload Evidence Image -->
            <b-col cols="12">
              <b-form-group class="font-weight-bold">
                <template #label>
                  {{ $t('topup.uploadEvidence') }}
                  <!-- <span class="text-danger">(*)</span> -->
                </template>
                <validation-provider
                  ref="refProviderImgFile"
                  v-slot="{ errors }"
                  rules=""
                  :name="$t('topup.uploadEvidence')"
                >
                  <b-form-file
                    v-model="imgFile"
                    accept="image/*"
                    :state="errors[0] ? false : null"
                    :placeholder="$t('topup.placeholderChooseImage')"
                    :drop-placeholder="$t('topup.placeholderDropImage')"
                    @change="onImgFileChange"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="imgBlobUrl"
              cols="10"
              offset="1"
            >
              <b-img
                :src="imgBlobUrl"
                fluid
                center
                alt="Evidence Image"
                style="max-height: 600px"
                class="border shadow-lg mb-1"
              />
            </b-col>
          </b-row>

          <b-row>
            <!-- ANCHOR Note -->
            <b-col cols="12">
              <b-form-group class="font-weight-bold">
                <template #label>
                  {{ $t('topup.note') }}
                  <!-- <span class="text-danger">(*)</span> -->
                </template>
                <validation-provider
                  #default="{ errors }"
                  rules=""
                  :name="$t('topup.note')"
                >
                  <b-form-textarea
                    v-model="note"
                    class="overflow-auto"
                    no-resize
                    max-rows="4"
                    trim
                    :rows="['xs', 'sm'].includes( $store.getters['app/currentBreakPoint']) ? 1 : 2"
                    :state="errors[0] ? false : null"
                    :placeholder="$t('topup.note')"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupText,
  BFormFile,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

import IAmInputMoney from '@/components/IAmInputMoney.vue'
import IAmOverlay from '@/components/IAmOverlay.vue'

import { formatCurrency } from '@core/utils/filter'

import useTopupHandle from '../useTopupHandle'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,

    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BFormFile,
    BFormInvalidFeedback,
    BFormTextarea,
    BImg,
    BFormRadioGroup,

    IAmOverlay,
    IAmInputMoney,
  },

  props: {
    payCode: {
      type: String,
      default: '',
      required: true,
    },
    payAmount: {
      type: Number,
      default: 0,
      required: true,
    },
    payType: {
      type: String,
      default: '',
      required: true,
    },
  },

  setup(props, { emit }) {
    const autoPayment = ref(true)

    const {
      evidenceUrl,
      note,
      imgFile,
      imgBlobUrl,
      disabledConfirm,
      resetEvidenceData,
      onImgFileChange,
      onConfirmManual,
      loading,
    } = useTopupHandle()

    const payAmountTemp = ref(0)

    watch(() => props.payAmount, newValue => {
      payAmountTemp.value = newValue
    })
    function onHiddenTopupConfirm() {
      resetEvidenceData()
      emit('hidden')
    }

    function callbackOnConfirmSuccess() {
      emit('refetch-data')
      // this.$bvModal.hide('id-topup-confirm-manual')
      emit('confirm-manual-success')
    }

    const autoPaymentOptions = [
      {
        text: 'Có',
        value: true,
      },
      {
        text: 'Không',
        value: false,
      },
    ]

    return {
      evidenceUrl,
      note,
      disabledConfirm,
      imgFile,
      imgBlobUrl,

      formatCurrency,
      onImgFileChange,
      onConfirmManual,
      onHiddenTopupConfirm,
      callbackOnConfirmSuccess,

      loading,
      payAmountTemp,
      autoPaymentOptions,
      autoPayment,
    }
  },
}
</script>

<style>
.custom-file-label {
  overflow-y: hidden;
}
</style>
