<template>
  <div id="search-train">
    <v-select
      ref="vSelectRef"
      :value="valueProp"
      :calculate-position="withPopper"
      :clearable="false"
      :options="getListTrainStation"
      :filter="filterFn"
      label="code"
      @option:selected="handleChooseAirport"
    >
      <template
        slot="selected-option"
        slot-scope="data"
      >
        <div class="fw-800 font-medium-4">
          <span class="">
            {{ data.name }}
          </span>
          <span class="text-success ml-50">{{ data.code }}</span>
        </div>
      </template>

      <template #option="data">
        <div class="d-flex justify-content-start text-truncate">
          <strong class="mr-1 fw-700">
            {{ data.name }}
          </strong>
          <strong class="text-success">( {{ data.code }} )</strong>
        </div>
      </template>

      <template #no-options="{ search, searching }">
        <template v-if="searching">
          {{ $t('flight.noResultFound') }} <span class="font-medium-3 fw-700">{{ search }}</span>
        </template>
        <template v-else-if="!search">
          {{ $t('flight.noOptions') }}
        </template>
        <template v-else-if="stateLoadingCategory">
          <div class="d-fex-center">
            <span class="mr-50">{{ $t('train.search.loadingData') }}</span>
            <b-spinner variant="primary" />
          </div>
        </template>
      </template>
    </v-select>
  </div>
</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue'
import {
  ref,
  computed,
  toRefs,
  watchEffect,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import isEmpty from 'lodash/isEmpty'
import { createPopper } from '@popperjs/core'
import { v4 as uuidv4 } from 'uuid'

import { removeAccentsUpperCaseFormatterNotTrim } from '@core/comp-functions/forms/formatter-input'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BSpinner,
    vSelect,

  },
  props: {
    dataProp: {
      type: [Object, String],
      default: () => {},
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      getListTrainStation,
      stateLoadingCategory,
    } = useTrainHandle()

    const { dataProp } = toRefs(props)
    const vSelectRef = ref()
    const station = ref(null)

    const valueProp = computed(() => station.value)
    const options = computed(() => getListTrainStation.value || [])

    watchEffect(() => {
      if (typeof dataProp.value === 'string' && !isEmpty(dataProp.value) && !stateLoadingCategory.value) {
        const temp = getListTrainStation.value.find(it => it.code === dataProp.value)
        if (temp) {
          station.value = temp
        }
      } else if (dataProp.value) {
        station.value = dataProp.value
      }
    })

    const handleChooseAirport = (item, closeDropdown = false) => {
      emit('update:data-prop', item)
      if (closeDropdown) vSelectRef.value.searchEl.blur() // -> close dropdown programmatically
    }

    function filterFn(options, search) {
      const str = removeAccentsUpperCaseFormatterNotTrim(search).trim()
      return options.filter(option => {
        const code = option.code
        const name = removeAccentsUpperCaseFormatterNotTrim(option.name)
        const searchKeys = removeAccentsUpperCaseFormatterNotTrim(option.searchKeys)
        return (
          code.includes(str)
          || name.includes(str)
          || searchKeys.includes(str)
        )
      })
    }

    const getOptionKey = val => `${val.id}-${uuidv4()}`

    return {
      handleChooseAirport,
      valueProp,
      vSelectRef,
      options,
      getListTrainStation,
      stateLoadingCategory,

      filterFn,
      getOptionKey,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownWidth = `calc(${Number(width.split('px')[0]) + 50}px )` || width // + 25px
      dropdownList.style.width = dropdownWidth
      dropdownList.style.zIndex = '10000'
      dropdownList.style.translate = `calc((${dropdownWidth} - ${this.right ? '0' : width})/2${this.right ? '*(-1)' : ''})`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          // {
          //   name: 'offset',
          //   options: {
          //     offset: [0, -1],
          //   },
          // },
          // {
          //   name: 'toggleClass',
          //   enabled: true,
          //   phase: 'write',
          //   fn({ state }) {
          //     component.$el.classList.toggle(
          //       'drop-up',
          //       state.placement === 'top',
          //     )
          //   },
          // },
        ],
      })
      return () => popper.destroy()
    },
  },
}
</script>

  <style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';

  #search-train ::v-deep {
    min-width: 170px;

    .vs__dropdown-toggle {
      border: none;
      height: 45px;
      padding: 0px !important;
    }
    .vs__dropdown-menu {
      min-width: 360px !important;
    }
    .vs__selected-options {
      min-width: 0;
      flex-wrap: nowrap;
      align-items: center;

      .vs__selected {
        min-width: 0;
      }
      .vs__search {
        font-size: 20px;
        font-weight: 600;
        width: 0px !important;
        padding: 0px !important;
      }
    }
    .vs__actions {
      cursor: pointer;
    }
  }

  .ps-customizer-area {
    height: calc(100% - 83px);
  }
  </style>
