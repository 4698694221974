<template>
  <section>
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-50"
      class="border"
    >
      <template #header>
        <div class="d-flex align-items-center justify-content-between w-100">
          <h5 class="m-0 py-75 pr-25 pr-sm-0">
            {{ $t('topup.tabInfo') }}
          </h5>
          <b-button
            v-if="canConfirmManual(topupData)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            class="px-75 px-md-2 py-50 py-md-1"
            @click="onOpenPopupConfirmManual(
              topupData.paymentCode,
              topupData.paymentAmount,
              topupData.type,
            )"
          >
            <feather-icon
              v-if="!isMobileView"
              icon="CheckCircleIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
            >
              {{ isMobileView ? 'Xác nhận thủ công' : $t('topup.confirmManual') }}
            </span>
          </b-button>
        </div>
      </template>

      <b-card-body class="p-0">
        <b-form>
          <b-row class="mt-1">
            <!-- ANCHOR Payment Code -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.tableColumns.paymentCode')"
              >
                <b-form-input
                  :value="topupData.paymentCode"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- ANCHOR Payment Amount -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.paymentAmount')"
              >
                <b-input-group
                  disabled
                >
                  <b-form-input
                    :value="formatCurrency(topupData.paymentAmount)"
                    disabled
                  />
                  <template #append>
                    <b-input-group-text>
                      {{ $t('agency.currency') }}
                    </b-input-group-text>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- ANCHOR Payment Status -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.paymentStatus')"
              >
                <b-form-input
                  :value="$t(resolveTopupStatus(topupData.paymentStatus))"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- ANCHOR Bank Account Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.bankAccountName')"
              >
                <b-form-input
                  :value="topupData.bankAccountName"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- ANCHOR Bank Account Number -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.bankAccountNo')"
              >
                <b-form-input
                  :value="topupData.bankAccountNo"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- ANCHOR Bank Code -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.bankCode')"
              >
                <b-form-input
                  :value="topupData.bankCode"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- ANCHOR Sender Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.senderName')"
              >
                <b-form-input
                  :value="topupData.senderName"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- ANCHOR Sender ID -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.senderId')"
              >
                <b-form-input
                  :value="topupData.senderId"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- ANCHOR Sender Entity -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.senderEntity')"
              >
                <b-form-input
                  :value="topupData.senderEntity"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- ANCHOR Recipient ID -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.recipientId')"
              >
                <b-form-input
                  :value="topupData.recipientId"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- ANCHOR Recipient Entity -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.recipientEntity')"
              >
                <b-form-input
                  :value="topupData.recipientEntity"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- ANCHOR Note -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.note')"
              >
                <b-form-textarea
                  :value="topupData.note"
                  disabled
                  class="overflow-auto"
                  no-resize
                  max-rows="4"
                  :rows="['xs', 'sm'].includes( $store.getters['app/currentBreakPoint']) ? 1 : 2"
                />
              </b-form-group>
            </b-col>
            <!-- ANCHOR Evidence URL -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.evidenceUrl')"
              >
                <b-form-textarea
                  :value="topupData.evidenceUrl"
                  disabled
                  class="overflow-auto"
                  no-resize
                  max-rows="4"
                  :rows="['xs', 'sm'].includes( $store.getters['app/currentBreakPoint']) ? 1 : 2"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- ANCHOR createdAt -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.tableColumns.created')"
              >
                <b-form-input
                  :value="dateTime(topupData.createdAt)"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- ANCHOR updatedAt -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                :label="$t('topup.tableColumns.updated')"
              >
                <b-form-input
                  :value="dateTime(topupData.updatedAt)"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div
                class="d-flex-center"
              >
                <b-img
                  style="max-width: 100vw;"
                  :src="topupData.qrCodeUrl"
                />
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>

    <!--  ANCHOR Button Actions -->
    <div class="d-flex justify-content-center">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-danger"
        :to="{ name: 'apps-topupSender-list' }"
      >
        <feather-icon
          icon="RotateCcwIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('back') }}</span>
      </b-button>
    </div>

    <TopupPopupConfirmManual
      :pay-code="paymentCode"
      :pay-amount="paymentAmount"
      :pay-type="paymentType"
      @hidden="resetPaymentData"
      @confirm-manual-success="$emit('confirm-manual-success')"
    />
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupText,
  BFormTextarea,
  BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { resolveTopupStatus } from '@/constants/selectOptions'

import {
  formatCurrency, dateTime,
} from '@core/utils/filter'

import useTopupHandle from '../useTopupHandle'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BFormTextarea,
    BButton,
    BImg,

    TopupPopupConfirmManual: () => import('../topup-popup/TopupPopupConfirmManual.vue'),
  },
  props: {
    topupData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      // for topup confirm manual
      paymentCode,
      paymentAmount,
      paymentType,

      canConfirmManual,
      resetPaymentData,
      onOpenPopupConfirmManual,
    } = useTopupHandle()

    return {
      formatCurrency,
      dateTime,

      // for topup confirm manual
      paymentCode,
      paymentAmount,
      paymentType,

      canConfirmManual,
      resetPaymentData,
      onOpenPopupConfirmManual,
      resolveTopupStatus,
    }
  },
}
</script>
