var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-table', {
    staticClass: "position-relative bg-white",
    attrs: {
      "items": _vm.bookingData.bookingInfos,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "small": "",
      "hover": "",
      "bordered": "",
      "fields": _vm.tableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("train.detail.bookingInfo.columns.".concat(column.label))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(fullName)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-nowrap"
        }, [_vm._v(" " + _vm._s(item.fullName) + " ")])];
      }
    }, {
      key: "cell(ticketId)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.ticketId) + " ")])];
      }
    }, {
      key: "cell(identifyNumber)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.identifyNumber) + " ")])];
      }
    }, {
      key: "cell(paxType)",
      fn: function fn(_ref4) {
        var _vm$getPassengerTypeB;
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s((_vm$getPassengerTypeB = _vm.getPassengerTypeByPassengerTargetCode(item.passengerTargetCode)) !== null && _vm$getPassengerTypeB !== void 0 ? _vm$getPassengerTypeB : item.passengerTargetCode) + " ")])];
      }
    }, {
      key: "cell(seatType)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.seatTypeName) + " ")]), item !== null && item !== void 0 && item.seatTypeCode ? _c('div', {
          staticClass: "font-italic"
        }, [_vm._v(" (" + _vm._s(item === null || item === void 0 ? void 0 : item.seatTypeCode) + ") ")]) : _vm._e()])];
      }
    }, {
      key: "cell(trip)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: "".concat(item.departureName, " - ").concat(item.arrivalName),
            expression: "`${item.departureName} - ${item.arrivalName}`",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.departure) + " - " + _vm._s(item.arrival) + " ")])];
      }
    }, {
      key: "cell(ticketInfo)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('div', {
          staticClass: "fw-600"
        }, [_vm._v(" " + _vm._s(item.trainCode) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$t('train.coach')) + ": "), _c('span', {
          staticClass: "fw-600"
        }, [_vm._v(_vm._s(item.coachName))]), _vm._v(", " + _vm._s(_vm.$t('train.seatNumber')) + ": "), _c('span', {
          staticClass: "fw-600"
        }, [_vm._v(_vm._s(item.seatNumber))])])])];
      }
    }, {
      key: "cell(departureTime)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', [_c('div', [_vm._v(_vm._s(item.departureHour))]), _c('div', [_vm._v(_vm._s(_vm.convertISODateTime(item.departureTime).dayAndMonth))])])];
      }
    }, {
      key: "cell(arrivalTime)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', [_c('div', [_vm._v(_vm._s(item.arrivalHour))]), _c('div', [_vm._v(_vm._s(_vm.convertISODateTime(item.arrivalTime).dayAndMonth))])])];
      }
    }, {
      key: "cell(totalPrice)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "text-nowrap fw-700"
        }, [_c('div', [_vm._v(_vm._s(_vm.formatCurrency(_vm.showPriceTicket ? item === null || item === void 0 ? void 0 : item.totalPriceTicket : item === null || item === void 0 ? void 0 : item.totalPricePaid)))])])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref11) {
        var _item$status;
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.statusText))]), _c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$status = item.status) === null || _item$status === void 0 ? void 0 : _item$status.description))])])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [item.pdfUrl ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('train.detail.bookingInfo.downloadTicket'),
            expression: "$t('train.detail.bookingInfo.downloadTicket')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark",
            "href": item === null || item === void 0 ? void 0 : item.pdfUrl,
            "target": "_blank"
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "fileArrowDown",
            "size": "20"
          }
        })], 1) : _vm._e()], 1)];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }