var render = function () {
  var _vm$invoiceData, _vm$invoiceData2, _vm$invoiceData3, _vm$invoiceData4, _vm$invoiceData5, _vm$cancelInvoiceData, _vm$cancelInvoiceData3, _vm$cancelInvoiceData5, _vm$cancelInvoiceData6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-cancel-invoice",
      "title": _vm.$t('invoice.cancel.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "p-1",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-footer": "",
      "size": "lg"
    },
    on: {
      "show": _vm.onShowHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('BCard', {
    staticStyle: {
      "background": "#e2e4e8"
    }
  }, [_c('BRow', [_c('BCol', {
    staticClass: "d-flex gap-2 flex-column",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "d-flex-between"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.columns.symbol')) + " ")]), _c('div', [_vm._v(_vm._s((_vm$invoiceData = _vm.invoiceData) === null || _vm$invoiceData === void 0 ? void 0 : _vm$invoiceData.symbol))])]), _c('div', {
    staticClass: "d-flex-between"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.columns.invNumber')) + " ")]), _c('div', [_vm._v(_vm._s((_vm$invoiceData2 = _vm.invoiceData) === null || _vm$invoiceData2 === void 0 ? void 0 : _vm$invoiceData2.invNumber))])]), _c('div', {
    staticClass: "d-flex-between"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.columns.taxAuthorityCode')) + " ")]), _c('div', [_vm._v(_vm._s((_vm$invoiceData3 = _vm.invoiceData) === null || _vm$invoiceData3 === void 0 ? void 0 : _vm$invoiceData3.releasedInvCode))])]), _c('div', {
    staticClass: "d-flex-between"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.columns.date')) + " ")]), _c('div', [_vm._v(_vm._s(_vm.convertISODateTime((_vm$invoiceData4 = _vm.invoiceData) === null || _vm$invoiceData4 === void 0 ? void 0 : _vm$invoiceData4.createdAt).date))])])]), _c('BCol', {
    staticClass: "d-flex gap-2 flex-column justify-content-center text-right",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.totalPayment')) + ": ")]), _c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$invoiceData5 = _vm.invoiceData) === null || _vm$invoiceData5 === void 0 ? void 0 : _vm$invoiceData5.totalAmount)) + " ")]), _c('div', [_c('BBadge', {
    attrs: {
      "variant": "light",
      "pill": ""
    }
  }, [_c('div', {
    staticClass: "text-dark"
  }, [_vm._v(" Chưa gửi TB sai sót đến CQT ")])])], 1)])], 1)], 1), _c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('invoice.cancel.cancelDate'),
      "vid": "cancelDate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "cancelDate"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.cancelDate')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "d-none",
          model: {
            value: _vm.cancelInvoiceData.cancelDate,
            callback: function callback($$v) {
              _vm.$set(_vm.cancelInvoiceData, "cancelDate", $$v);
            },
            expression: "cancelInvoiceData.cancelDate"
          }
        }), _c('div', {
          staticClass: "p-0 form-control",
          class: errors[0] ? 'is-invalid' : ''
        }, [_c('flat-pickr', {
          attrs: {
            "id": "cancelDate",
            "name": _vm.$t('invoice.cancel.cancelDate'),
            "config": Object.assign({}, _vm.configFlatPickr, {
              altInputClass: 'form-control border-transparent px-50 py-0 height-fit'
            }),
            "placeholder": _vm.$t('topup.placeholderSelectDate')
          },
          model: {
            value: _vm.cancelInvoiceData.cancelDate,
            callback: function callback($$v) {
              _vm.$set(_vm.cancelInvoiceData, "cancelDate", $$v);
            },
            expression: "cancelInvoiceData.cancelDate"
          }
        })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('invoice.cancel.reason'),
      "vid": "reason"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "reason"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.reason')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-textarea', {
          staticStyle: {
            "overflow-y": "auto"
          },
          attrs: {
            "id": "reason",
            "rows": "2",
            "max-rows": "6",
            "placeholder": _vm.$t('invoice.cancel.reason'),
            "state": errors[0] ? false : null
          },
          model: {
            value: _vm.cancelInvoiceData.reason,
            callback: function callback($$v) {
              _vm.$set(_vm.cancelInvoiceData, "reason", typeof $$v === 'string' ? $$v.trim() : $$v);
            },
            expression: "cancelInvoiceData.reason"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "",
      "name": _vm.$t('invoice.cancel.sendCancellationNotification'),
      "vid": "sendCancellationNotification"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-group', [_c('b-form-checkbox', {
          staticStyle: {
            "overflow": "auto"
          },
          attrs: {
            "id": "sendCancellationNotification",
            "placeholder": _vm.$t('invoice.cancel.sendCancellationNotification'),
            "state": errors[0] ? false : null
          },
          on: {
            "change": _vm.onChangeSendCancellationNotificationHandle
          },
          model: {
            value: _vm.cancelInvoiceData.sendCancellationNotification,
            callback: function callback($$v) {
              _vm.$set(_vm.cancelInvoiceData, "sendCancellationNotification", $$v);
            },
            expression: "cancelInvoiceData.sendCancellationNotification"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.sendCancellationNotification')) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": (_vm$cancelInvoiceData = _vm.cancelInvoiceData) !== null && _vm$cancelInvoiceData !== void 0 && _vm$cancelInvoiceData.sendCancellationNotification ? 'required' : '',
      "name": _vm.$t('invoice.cancel.receiverName'),
      "vid": "receiverName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "receiverName"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              var _vm$cancelInvoiceData2;
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.receiverName')) + " "), (_vm$cancelInvoiceData2 = _vm.cancelInvoiceData) !== null && _vm$cancelInvoiceData2 !== void 0 && _vm$cancelInvoiceData2.sendCancellationNotification ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticStyle: {
            "overflow": "auto"
          },
          attrs: {
            "id": "receiverName",
            "placeholder": _vm.$t('invoice.cancel.receiverName'),
            "state": errors[0] ? false : null
          },
          model: {
            value: _vm.cancelInvoiceData.receiverName,
            callback: function callback($$v) {
              _vm.$set(_vm.cancelInvoiceData, "receiverName", typeof $$v === 'string' ? $$v.trim() : $$v);
            },
            expression: "cancelInvoiceData.receiverName"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "d-flex flex-column gap-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex gap-1"
  }, [_c('validation-provider', {
    staticClass: "flex-1",
    attrs: {
      "rules": "email".concat((_vm$cancelInvoiceData3 = _vm.cancelInvoiceData) !== null && _vm$cancelInvoiceData3 !== void 0 && _vm$cancelInvoiceData3.sendCancellationNotification ? '|required' : ''),
      "name": _vm.$t('invoice.cancel.email'),
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "email"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              var _vm$cancelInvoiceData4;
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.email')) + " "), (_vm$cancelInvoiceData4 = _vm.cancelInvoiceData) !== null && _vm$cancelInvoiceData4 !== void 0 && _vm$cancelInvoiceData4.sendCancellationNotification ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticStyle: {
            "overflow": "auto"
          },
          attrs: {
            "id": "email",
            "placeholder": _vm.$t('invoice.cancel.email'),
            "state": errors[0] ? false : null
          },
          model: {
            value: _vm.cancelInvoiceData.email,
            callback: function callback($$v) {
              _vm.$set(_vm.cancelInvoiceData, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
            },
            expression: "cancelInvoiceData.email"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
      }
    }])
  }), _c('div', {
    staticStyle: {
      "padding-top": "25px"
    }
  }, [_c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top.hover.window",
      value: _vm.$t('invoice.cancel.addCc'),
      expression: "$t('invoice.cancel.addCc')",
      modifiers: {
        "top": true,
        "hover": true,
        "window": true
      }
    }],
    staticClass: "p-50",
    attrs: {
      "id": "cc-button",
      "variant": "flat-primary"
    }
  }, [_vm._v(" CC ")]), _c('InvoiceAddCcPopover', {
    attrs: {
      "target": "cc-button",
      "type": "cc"
    },
    on: {
      "push": function push(val) {
        return _vm.onAddCcHandle('cc', val);
      }
    }
  }), _vm._v(" | "), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top.hover.window",
      value: _vm.$t('invoice.cancel.addBcc'),
      expression: "$t('invoice.cancel.addBcc')",
      modifiers: {
        "top": true,
        "hover": true,
        "window": true
      }
    }],
    staticClass: "p-50",
    attrs: {
      "id": "bcc-button",
      "variant": "flat-primary"
    }
  }, [_vm._v(" BCC ")]), _c('InvoiceAddCcPopover', {
    attrs: {
      "target": "bcc-button",
      "type": "bcc"
    },
    on: {
      "push": function push(val) {
        return _vm.onAddCcHandle('bcc', val);
      }
    }
  })], 1)], 1), (_vm$cancelInvoiceData5 = _vm.cancelInvoiceData) !== null && _vm$cancelInvoiceData5 !== void 0 && _vm$cancelInvoiceData5.cc && _vm.cancelInvoiceData.cc.length ? _c('div', [_vm._v(" CC: "), _vm._l(_vm.cancelInvoiceData.cc, function (ccItem, ccIndex) {
    return _c('BBadge', {
      key: ccIndex,
      staticClass: "mx-25",
      attrs: {
        "variant": "light-info",
        "pills": ""
      }
    }, [_c('div', {
      staticClass: "d-flex-center gap-1"
    }, [_c('span', [_vm._v(_vm._s(ccItem))]), _c('feather-icon', {
      staticClass: "cursor-pointer",
      attrs: {
        "icon": "XIcon"
      },
      on: {
        "click": function click($event) {
          return _vm.removeCcItem('cc', ccItem);
        }
      }
    })], 1)]);
  })], 2) : _vm._e(), (_vm$cancelInvoiceData6 = _vm.cancelInvoiceData) !== null && _vm$cancelInvoiceData6 !== void 0 && _vm$cancelInvoiceData6.bcc && _vm.cancelInvoiceData.bcc.length ? _c('div', [_vm._v(" BCC: "), _vm._l(_vm.cancelInvoiceData.bcc, function (bccItem, bccIndex) {
    return _c('BBadge', {
      key: bccIndex,
      staticClass: "mx-25",
      attrs: {
        "variant": "light-info",
        "pills": ""
      }
    }, [_c('div', {
      staticClass: "d-flex-center gap-1"
    }, [_c('span', [_vm._v(_vm._s(bccItem))]), _c('feather-icon', {
      staticClass: "cursor-pointer",
      attrs: {
        "icon": "XIcon"
      },
      on: {
        "click": function click($event) {
          return _vm.removeCcItem('bcc', bccItem);
        }
      }
    })], 1)]);
  })], 2) : _vm._e()])], 1), _c('div', {
    staticClass: "d-flex-center gap-2 mt-1"
  }, [_c('BButton', {
    staticClass: "flex-1",
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.onCancelModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c('BButton', {
    staticClass: "flex-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.onSubmitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.submit')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }