var render = function () {
  var _vm$invoicesNotToSign, _vm$invoicesNotToSign2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-sign-invoice",
      "size": "lg",
      "centered": "",
      "header-bg-variant": "light-primary",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "body-class": "p-1 modal-sign-invoice",
      "title-class": "h2 font-weight-bolder text-dark",
      "title": _vm.isBatch ? _vm.$t('invoice.releaseBatch.title') : _vm.$t('invoice.btn.signAndReleaseInvoice'),
      "hide-footer": "",
      "hide-header-close": ""
    },
    on: {
      "show": _vm.onShowHandle,
      "hide": _vm.onHideHandle
    }
  }, [_vm.isProcessingReleaseBatch ? _c('BAlert', {
    staticClass: "px-2 py-50",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.releaseBatch.processingError')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex-center align-items-start flex-column gap-2 mb-50"
  }, [_c('b', [_vm._v(_vm._s(_vm.isBatch ? _vm.$t('invoice.releaseBatch.invoiceChosen') : _vm.$t('invoice.signModal.invoiceChosen')) + ": ")]), _c('b-table', {
    ref: "refSignAndReleaseBatchInvoiceListTable",
    staticClass: "mb-0 position-relative",
    staticStyle: {
      "max-height": "60vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.isBatch ? _vm.invoicesToSignAndRelease : [_vm.invoiceData],
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "bordered": "",
      "fields": _vm.invoicesToSignAndReleaseTableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.invoicesToSignAndReleaseTableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(symbol)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.templateNumber) + _vm._s(item.symbol) + " ")];
      }
    }, {
      key: "cell(invNumber)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-dark fw-700"
        }, [_vm._v(" " + _vm._s(item.invNumber) + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).date) + " ")])];
      }
    }], null, true)
  }), (_vm$invoicesNotToSign = _vm.invoicesNotToSignAndRelease) !== null && _vm$invoicesNotToSign !== void 0 && _vm$invoicesNotToSign.length && _vm.isBatch ? _c('b', [_vm._v(" " + _vm._s(_vm.$t('invoice.releaseBatch.invoiceCannotRelease')) + " "), _c('b', {
    staticClass: "text-info fw-700"
  }, [_vm._v("(" + _vm._s((_vm$invoicesNotToSign2 = _vm.invoicesNotToSignAndRelease) === null || _vm$invoicesNotToSign2 === void 0 ? void 0 : _vm$invoicesNotToSign2.length) + ")")]), _vm._v(" - "), _vm._l(_vm.groupBy(_vm.invoicesNotToSignAndRelease, 'reason'), function (groupByInvoiceNotToCancelItem, groupByInvoiceNotToCancelIndex) {
    return _c('span', {
      key: groupByInvoiceNotToCancelIndex
    }, [_vm._l(groupByInvoiceNotToCancelItem, function (invoiceNotToCancelItem, invoiceNotToCancelIndex) {
      return _c('span', {
        key: invoiceNotToCancelIndex
      }, [_c('span', {
        staticClass: "fw-700 font-italic text-danger"
      }, [_vm._v(_vm._s(invoiceNotToCancelItem.invNumber))]), invoiceNotToCancelIndex !== groupByInvoiceNotToCancelItem.length - 1 ? _c('span', [_vm._v(", ")]) : _c('span', {
        staticClass: "font-weight-light font-italic"
      }, [_vm._v(" (" + _vm._s(_vm.$t("invoice.msg.".concat(invoiceNotToCancelItem.reason))) + ")")])]);
    }), groupByInvoiceNotToCancelIndex !== groupByInvoiceNotToCancelItem.length - 1 ? _c('span', [_vm._v(" ; ")]) : _vm._e()], 2);
  })], 2) : _vm._e()], 1), _c('div', [_c('b-form-group', {
    attrs: {
      "label-for": "signType"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('b', {
          staticClass: "text-16px"
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.signModal.signType')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "id": "signType",
      "name": _vm.$t('invoice.signModal.signType'),
      "options": _vm.SIGN_TYPE_OPTIONS,
      "label": "label",
      "clearable": false,
      "reduce": function reduce(val) {
        return val.value;
      },
      "placeholder": _vm.$t('invoice.signModal.phSignType')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("invoice.signModal.".concat(data.label))) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("invoice.signModal.".concat(data.label))) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.signType,
      callback: function callback($$v) {
        _vm.signType = $$v;
      },
      expression: "signType"
    }
  })], 1), !_vm.isToken ? _c('div', [_c('b-table', {
    ref: "refTable",
    attrs: {
      "items": _vm.certificatesList,
      "fields": _vm.tableColumns,
      "responsive": "",
      "small": "",
      "hover": "",
      "no-border-collapse": "",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "busy": _vm.isLoading,
      "select-mode": "single",
      "selectable": ""
    },
    on: {
      "row-clicked": _vm.onRowSelected
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('div', {
            key: column.key,
            staticClass: "text-dark d-flex-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.signModal.columns.".concat(column.key))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": row.item.serial === _vm.certificateSelected
          },
          on: {
            "change": function change($event) {
              return _vm.onRowSelected(row.item);
            }
          }
        })];
      }
    }, {
      key: "cell(organizationName)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.organizationName) + " ")];
      }
    }, {
      key: "cell(serial)",
      fn: function fn(row) {
        return [_c('code', [_vm._v(" " + _vm._s(row.item.serial) + " ")])];
      }
    }, {
      key: "cell(startDate)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(row.item.startDate).date) + " ")];
      }
    }, {
      key: "cell(endDate)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(row.item.endDate).date) + " ")];
      }
    }], null, true)
  })], 1) : _c('div', [!_vm.isPluggingInUSB ? _c('b-alert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Vui lòng kết nối USB Token và bật phần mềm đọc token! ")]) : _c('div', [_c('b-table', {
    ref: "refTable",
    attrs: {
      "items": _vm.certificatesList,
      "fields": _vm.tableColumns,
      "responsive": "",
      "small": "",
      "hover": "",
      "no-border-collapse": "",
      "sticky-header": "",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "busy": _vm.isLoading,
      "select-mode": "single",
      "selectable": ""
    },
    on: {
      "row-clicked": _vm.onRowSelected
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('div', {
            key: column.key,
            staticClass: "text-dark d-flex-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.signModal.columns.".concat(column.key))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": row.item.serial === _vm.certificateSelected
          },
          on: {
            "change": function change($event) {
              return _vm.onRowSelected(row.item);
            }
          }
        })];
      }
    }, {
      key: "cell(organizationName)",
      fn: function fn(row) {
        var _row$item, _row$item2;
        return [_vm._v(" " + _vm._s(((_row$item = row.item) === null || _row$item === void 0 ? void 0 : _row$item.Subject) || ((_row$item2 = row.item) === null || _row$item2 === void 0 ? void 0 : _row$item2.subject)) + " ")];
      }
    }, {
      key: "cell(serial)",
      fn: function fn(row) {
        return [_c('code', [_vm._v(" " + _vm._s(row.item.serial) + " ")])];
      }
    }, {
      key: "cell(startDate)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(row.item.startDate).date) + " ")];
      }
    }, {
      key: "cell(endDate)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(row.item.endDate).date) + " ")];
      }
    }], null, true)
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex-between mt-1"
  }, [_c('BButton', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.onCloseHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]), _c('BButton', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.certificateSelected || _vm.isProcessingReleaseBatch
    },
    on: {
      "click": _vm.onSubmitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('submit')) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }